<template>
  <div class="columns  is-multiline is-constiable is-2">
    <div class="column is-full">
      <!-- Loader -->
      <div v-show="user === undefined" data-test="loader">
        Authenticating...
      </div>
      <!-- Offline instruction -->
      <div v-show="!networkOnLine" data-test="offline-instruction">
        Please check your connection, login feature is not available offline.
      </div>
    </div>

    <div class="column is-full">
      <figure class="image container is-128x128">
        <img
          alt="qr-code is-block"
          class="logo"
          src="@/assets/img/social-distancing.svg"
        />
      </figure>
    </div>

    <div class="column is-4 is-offset-4">
      <h1 class="title has-text-centered">Acesse o Lyne</h1>
      <h5 class="subtitle has-text-centered">
        Este acesso permite que você cadastre estabelecimentos e abre novas
        filas
      </h5>
    </div>

    <div class="column is-4 is-offset-4">
      <b-field label="E-mail">
        <b-input v-model="email" type="email" password-reveal> </b-input>
      </b-field>
    </div>

    <div class="column is-4 is-offset-4">
      <b-field label="Password">
        <b-input v-model="password" type="password" password-reveal> </b-input>
      </b-field>
    </div>
    <div class="column is-4 is-offset-4 has-text-centered">
      <button
        v-show="user !== undefined && !user && networkOnLine"
        data-test="login-btn"
        class="button is-primary is-fullwidth"
        @click="login('email')"
      >
        Entrar com email
      </button>
    </div>

    <div class="column is-4 is-offset-4">
      <button
        v-show="user !== undefined && !user && networkOnLine"
        data-test="login-btn"
        class="button is-danger is-outlined is-fullwidth"
        @click="login('google')"
      >
        Entrar com google
      </button>
    </div>

    <div class="column is-4 is-offset-4">
      <button
        v-show="user !== undefined && !user && networkOnLine"
        data-test="login-btn"
        class="button is-info is-outlined is-fullwidth"
        @click="login('facebook')"
      >
        Entrar com facebook
      </button>
    </div>

    <div class="column is-4 is-offset-4">
      <router-link
        v-show="user !== undefined && !user && networkOnLine"
        to="/register"
        data-test="login-btn"
        class="button is-info is-outlined is-fullwidth"
        @click="login('google')"
      >
        Crie uma conta com e-mail e senha
      </router-link>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { isNil } from 'lodash'
import firebase from 'firebase/app'
// import { desktop as isDekstop } from 'is_js'

export default {
  data: () => ({ loginError: null, email: '', password: '' }),
  head: {
    title: {
      inner: 'Faça login'
    },
    meta: [
      {
        name: 'description',
        content: 'Sign in or sign up to bento-starter',
        id: 'desc'
      }
    ]
  },
  computed: {
    ...mapState('authentication', ['user']),
    ...mapState('app', ['networkOnLine'])
  },
  watch: {
    user: {
      handler(user) {
        if (!isNil(user)) {
          const redirectUrl = isNil(this.$route.query.redirectUrl)
            ? '/locations'
            : this.$route.query.redirectUrl
          this.$router.push(redirectUrl)
        }
      },
      immediate: true
    }
  },
  methods: {
    ...mapMutations('authentication', ['setUser']),
    async login(type) {
      this.loginError = null
      if (type === 'google') {
        const provider = new firebase.auth.GoogleAuthProvider()
        this.setUser(undefined)

        try {
          // Firebase signin with popup is faster than redirect
          // but we can't use it on mobile because it's not well supported
          // when app is running as standalone on ios & android
          // eslint-disable-next-line no-unused-expressions
          // isDekstop()
          await firebase
            .auth()
            .signInWithPopup(provider)
            .then(result => {
              // This gives you a Google Access Token. You can use it to access the Google API.
              // const token = result.credential.accessToken
              // The signed-in user info.
              const { user } = result
              console.log(user)
              this.$store.commit('authentication/setUser', user.providerData[0])
            })
            .catch(error => {
              // Handle Errors here.
              const errorCode = error.code
              const errorMessage = error.message
              // The email of the user's account used.
              const { email } = error
              // The firebase.auth.AuthCredential type that was used.
              const { credential } = error
              // ...
              console.log(errorCode)
              console.log(errorMessage)
              console.log(email)
              console.log(credential)
            })

          // : await firebase.auth().signInWithRedirect(provider)
        } catch (err) {
          this.loginError = err
          this.setUser(null)
        }
        console.log(provider)
      } else if (type === 'email') {
        firebase
          .auth()
          .signInWithEmailAndPassword(this.email, this.password)
          .finally(() => {
            const user = firebase.auth().currentUser
            if (user) {
              const profile = {
                displayName: user.displayName,
                email: user.email,
                uid: user.uid,
                photoURL: user.photoURL
              }
              this.$buefy.toast.open({
                type: 'is-success',
                message: 'Login feito com sucesso',
                duration: 2000
              })
              this.$store.commit('authentication/setUser', profile)
            } else {
              this.$buefy.toast.open({
                type: 'is-danger',
                message: 'Por favor, verifique suas credenciais'
              })
            }
          })
          .catch(error => {
            // Handle Errors here.
            this.loginError = error.message
            this.setUser(null)
          })
      } else if (type === 'facebook') {
        const provider = new firebase.auth.FacebookAuthProvider()
        firebase
          .auth()
          .signInWithPopup(provider)
          .then(result => {
            this.setUser(undefined)
            // This gives you a Facebook Access Token. You can use it to access the Facebook API.
            // const token = result.credential.accessToken
            // The signed-in user info.
            const { user } = result

            this.$store.commit('authentication/setUser', user.providerData[0])

            // ...
          })
          .catch(error => {
            this.$buefy.toast.open({
              type: 'is-danger',
              message: 'error.message'
            })
            console.log(error)
            // ...
          })
      }
    }
  }
}
</script>
